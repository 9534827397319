html {
  overflow-x: hidden;
  overflow-y: scroll;
}

aside {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9;
  min-width: 200px;
  max-width: 100vw;
  padding: 15px 50px;
  color: white;
  text-align: center;
  background: $cardinale-middle-green;
  border-radius: 2px;
  transform: translate(-50%, -50%);

  &.messages {
  }

  &.privacy-popup {
    //width: 97vw;
    //height: 80vh;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 50px 15px;
    color: black;
    text-align: initial;
    background: white;
    box-shadow: 0 0 50px black;
    transform: initial;

    h1,
    h2,
    h3,
    h4,
    p {
      color: black;
    }

    .btn {
      @media (max-width: 767px) {
        width: 100% !important;
      }

      &.suggested {
        background: $cardinale-middle-green !important;
      }
    }

    div.privacy-popup {
      max-height: 100%;
      overflow-y: scroll;
    }
  }

  i.fa-times {
    position: fixed;
    top: 4px;
    right: 7px;
    cursor: pointer;
    zoom: 2;
  }

  .privacy-popup-scroller {
    position: fixed;
    top: -1px;
    right: 30px;
    cursor: pointer;
    zoom: 2;
  }
}

a.show-privacy-popup,
a.btn-send-cta {
  width: 100% !important;
  margin: 20px 0;
  text-align: center;

  @media (min-width: 768px) {
    width: calc(100% - 20px) !important;
  }
}

div.privacy-popup {
  padding-bottom: 40px;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.shake {
  animation: shake 0.5s;
  animation-iteration-count: 1;
}

.bg-success {
  background: $cardinale-light-green !important;
}

body {
  margin: 0;
  color: $cardinale-dark-green;
  font-weight: 200;
  font-family: "Cormorant", serif;
  background: white;
}

h1,
h2,
h3,
h4,
p {
  color: white;
}

textarea {
  overflow: hidden;
  resize: none;

  &.form-control {
    height: calc(1.6em + 0.75rem + 2px);
    padding: 6px 0 6px;
    border-width: 0 0 1px;
    border-radius: 0;

    &:focus {
      border-color: $cardinale-gold;
    }

    &:not([readonly]) {
      height: 10rem;
      overflow-y: auto;
    }
  }
}

a,
button,
.btn {
  transition: 300ms;
}

label {
  cursor: pointer;
}

input[type=text],
input[type=email],
input[type=password],
input[type=tel],
input[type=number] {
  padding: 0;
  font-size: 20px;
  border-width: 0 0 1px;
  border-radius: 0;

  &:focus {
    border-color: $cardinale-gold;
  }
}

input[type=radio] {
  vertical-align: top;
  cursor: pointer;
  zoom: 1.5;
}

.form-control[readonly] {
  background: initial;
  pointer-events: none;
}

form {
  &.locked {
    label {
      pointer-events: none;
    }
  }
}

.welcome-body,
.welcome-html {
  background-color: $cardinale-dark-green;
}

a {
  color: $cardinale-middle-green;

  &:hover {
    color: $cardinale-middle-green;
  }

  &:not([href]) {
    &:hover {
      color: white;
    }
  }
}

i {
  font-family: FontAwesome;
  font-style: normal;
}

nav.main-navbar {
  &.fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 4;
  }

  .lower-navigation {
    width: 100%;
    max-width: 1200px;
    text-align: center;

    a {
      margin: 5px;
      color: $cardinale-gold;
      font-weight: 400;
      font-size: 18px;
      text-decoration: none;
      background: $cardinale-dark-green;
      border: 1px solid #dcb274;
      border-radius: 2px;

      &:hover {
        color: $cardinale-dark-green;
        background: $cardinale-gold;
      }

      &.current_page_item {
        color: $cardinale-dark-green;
        background: $cardinale-gold;

        &:hover {
          color: $cardinale-dark-green;
          background: $cardinale-gold;
        }
      }
    }
  }
}

.form-control {
  &:focus {
    box-shadow: none;
  }
}

.links,
.welcome-menu {
  display: none;
  margin: 25px auto;

  @media #{$md} {
    display: block;
    margin: 25px -5px;
  }

  > a {
    padding: 0 25px;
    color: #636b6f;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.1rem;
    text-decoration: none;
  }

  //div {
  a {
    margin: 5px;
    padding: 0 25px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: $cardinale-dark-green;
    font-weight: 400;
    font-size: 18px;
    text-decoration: none;
    background: white;
    border: 1px solid $cardinale-gold;
    border-radius: 2px;

    &:hover {
      color: white;
      background: $cardinale-middle-green;
      border-color: $cardinale-middle-green;
    }

    &.current_page_item {
      background: $cardinale-gold;

      &:hover {
        color: $cardinale-dark-green;
        background: $cardinale-gold;
      }
    }
  }
  //}
}

.welcome-menu {
  max-width: 360px;
  margin: auto;
}

/* ----------------------------------- */

main {
  margin-top: 30px;
  padding: 0 15px 0;
  //padding-top: 102px;
  //
  //@media #{$mobile} {
  //  padding-top: 67px;
  //}
}

th,
.title {
  border-top: 40px;
}

.new-customer-title,
.explore-customer-title {
  padding-top: 500px;
}

.flex-center {
  align-items: center;

  /* display: flex; */
  justify-content: center;
}

.position-ref {
  position: relative;
}

.top-right {
  position: absolute;
  top: 18px;
  right: 10px;
}

.content {
  max-width: 400px;
  margin: auto;
  text-align: center;

  @media #{$md} {
    max-width: 1200px;
  }
}

.title {
  padding-top: 10px;
  padding-bottom: 15px;
  font-size: 84px;
}

.menu-item,
.img-thumbnail {
  width: 100%;
}

.main-title {
  > h1 {
    margin-top: 3vh;
    color: white;
  }
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  padding: 6px 12px;
  color: white;
  font-size: 18px;
  white-space: initial;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  background-image: none;
  border: 1px solid $cardinale-gold;
  border-radius: 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;

  &.suggested {
    background: fade_out($cardinale-light-green, 0.5);
  }

  &:focus,
  &:active:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  &.clear {
    background-color: $cardinale-light-green;
  }

  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
    background: $cardinale-middle-green;
    border-color: $cardinale-middle-green;
  }

  &:active {
    background-image: none;
    outline: 0;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
}
//
//.save,
//.btn {
//  color: $cardinale-gold;
//  border-color: $cardinale-gold;
//}

input {
  display: inline-flex !important;
  padding: 6px 12px;
  color: $cardinale-gold;
  font-weight: bold;
  font-size: 14px;
  border-color: transparent;
  border-radius: 5px;
  box-shadow: transparent;
}

.birth-input {
  display: inline-block;
  width: calc(33% - 3px) !important;
  height: 33px;
  padding: 0 10px;
  font-size: 20px;
  border-radius: 2px;

  &[readonly] {
    color: white;
    background: none;
    border-width: 0 0 1px;
  }
}

.newCustomer-button {
  display: table !important;
}

::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $cardinale-salmon;
  opacity: 1;

  /* Firefox */
}

::-moz-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $cardinale-salmon;
  opacity: 1;

  /* Firefox */
}

:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $cardinale-salmon;
  opacity: 1;

  /* Firefox */
}

::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $cardinale-salmon;
  opacity: 1;

  /* Firefox */
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $cardinale-salmon;
  opacity: 1;

  /* Firefox */
}

.box {
  width: 600px;
  margin-top: 100px;

  /* margin: 0 auto; */
  border: 1px solid $cardinale-gold;
  border-radius: 5px;
}

h3,
.navbar-brand {
  display: block;
  width: fit-content;
  color: $cardinale-gold !important;
  font-size: 12px;
  text-align: center;

  @media #{$md} {
    font-size: 18px;
  }

  img.center-logo {
    max-width: 80px;
    vertical-align: baseline;

    @media #{$md} {
      max-width: 140px;
    }
  }
}

.card {
  background: transparent;
}

.navbar {
  background: transparent !important;
  border-bottom: solid 1px;
}

.navbar-toggler {
  position: absolute;
  top: 15px;
  background: $cardinale-gold;
}

.navbar-light {
  .navbar-nav {
    .nav-link {
      color: $cardinale-gold;

      &:hover {
        color: white;
      }

      &:focus {
        color: $cardinale-gold;
      }
    }

    .dropdown-menu {
      @media #{$mobile} {
        position: static !important;
        transform: none !important;
      }
    }

    .show > .nav-link {
      color: white;
    }
  }
}

#navbarSupportedContent {
  @media #{$mobile} {
    padding: 15px;
  }
}

.dropdown-item {
  color: $cardinale-gold;
}

.home-card-text {
  padding: 20px;
}

.app {
  min-height: 100vh;
  background: white;
}

.main-title,
.main-navbar {
  background: #071c0c !important;

  .navbar-collapse {
    justify-content: center;
  }
}

body {
  height: 100%;
  background-image:
    linear-gradient(
      0deg,
      rgba(8, 32, 6, 1) 5%,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0) 70%,
      rgba(8, 32, 6, 1) 95%
    ),
    url("/images/welcome-background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  input,
  textarea {
    color: $cardinale-dark-green;
  }

  a,
  button,
  h4 {
    color: white;
  }

  form {
    &.locked {
      input,
      textarea,
      a {
        color: white;
      }
    }
  }

  .app {
    background-color: $cardinale-dark-green;
    opacity: 0.8;
  }

  main {
    color: white;
  }
}

.panel-heading {
  border: solid 1px;
}

.panel-body {
  border-right: solid 1px;
  border-bottom: solid 1px;
  border-left: solid 1px;
}

.panel-default {
  margin-bottom: 2rem;
}

.resultTable {
  text-align: left;

  ul {
    padding: 0;
    list-style-type: none;

    li {
      &.resultHead {
        position: relative;
        padding: 10px 0;
        font-size: 22px;
        border-bottom: 1px solid white;
        cursor: pointer;

        @media #{$md} {
          text-align: center;
        }

        &.active {
          font-weight: 800;
          font-size: 24px;
          border-bottom-width: 2px;

          &::after {
            transform: rotate(270deg);
          }
        }

        &::after {
          position: absolute;
          right: 0;
          transform: rotate(90deg);
          content: '▶';
        }
      }

      &.resultBody {
        display: none;

        @media #{$md} {
          padding-top: 10px;
        }

        form[name=form-customer],
        form[name=form-user] {
          > a {
            display: block;

            &:hover {
              color: white;
            }

            &.pdf-link {
              margin: 10px 0;
            }
          }

          @media #{$md} {
            display: inline-block;
            width: calc(50% - 4px);
            vertical-align: top;
          }

          @media #{$md} {
            padding-top: 15px;
          }

          h4 {
            padding-top: 25px;
            font-weight: 800;
            font-size: 20px;
            text-align: left;

            @media #{$md} {
              padding-top: 0;
            }
          }

          label {
            @media #{$md} {
              padding-right: 20px;
            }

            @media #{$lg} {
              width: calc(50% - 3px);
            }
          }

          .permission {
            padding-top: 20px;
          }
        }

        .btn {
          width: calc(100% / 3 - 3px);

          @media #{$md} {
            width: calc(100% / 3 - 9px);
          }

          &.button-customer-edit {
            width: calc(50% - 23px);

            @media (max-width: 767px) {
              width: 100%;
            }
          }

          &.edit {
            @media (max-width: 768px) {
              width: 100%;
            }
          }
        }

        .order-of-wrapper {
          @media #{$md} {
            display: inline-block;
            width: calc(50% - 4px);
            padding: 15px 0 0 20px;
            vertical-align: top;
          }

          .newOrderBlock {
            padding-bottom: 30px;

            a.button-new-order {
              width: 50%;
              margin-top: 15px;

              @media (max-width: 768px) {
                width: 100%;
              }
            }
          }
        }

        .order-of,
        h4 {
          padding-top: 40px;
          font-weight: 800;
          font-size: 20px;
          text-align: left;

          @media #{$md} {
            padding-top: 0;
          }

          span {
            @media #{$md} {
              display: none;
            }
          }
        }
      }

      label {
        width: 100%;
        margin: 0.8rem 0;
        font-size: 16px;

        &.details {
          padding: 0 17px;
        }

        &.privacy-policy-acceptance {
          &.accepted {
            pointer-events: none;
          }
        }

        input {
          &[name=name],
          &[name=surname],
          &[name=email],
          &[name=cellphone] {
            font-weight: bold;
          }
        }

        .is-danger {
          display: none;
          color: red;
        }
      }

      .birth-date {
        display: inline-block;
        width: 100%;
        margin: 0.8rem 0;
        padding-right: 15px;
        font-size: 16px;

        @media (max-width: 767px) {
          width: calc(100% + 3px);
          padding: 0;
        }

        input {
          text-align: center;
        }
      }

      input {
        height: initial;

        &[name=date] {
          max-height: 29px;
        }
      }

      [readonly] {
        pointer-events: none;

        + .ui-datepicker-trigger {
          pointer-events: none;
        }
      }

      .value {
        font-size: 18px;
      }

      .new-order {
        label {
          text-align: initial;

          textarea {
            font-size: 20px;
          }
        }

        form[name=form-purchase] {
          position: relative;
          width: 100%;

          &.active {
            .trigger {
              span {
                transform: rotate(270deg) !important;
              }
            }
          }

          .head {
            text-align: initial;

            label {
              &.date {
                max-width: 120px;
              }

              &.details {
              }
            }

            .trigger {
              margin: auto;
              padding: 20px 0;
              font-size: 20px;
              cursor: pointer;

              span {
                position: absolute;
                transform: rotate(90deg);
              }
            }

            input:read-only {
              cursor: pointer;
            }
          }

          .actions {
            .btn {
              width: calc(100% / 3 - 2.6px);
              text-align: center;

              @media #{$lg} {
                margin-right: 0;
              }
            }
          }

          div.date {
            padding-top: 8px;
          }
        }
      }
    }
  }

  input:not(.btn),
  textarea {
    padding: 0;
    border-width: 0 0 1px;
    border-radius: 2px;

    &:not(readonly) {
      padding: 0 10px;
    }
  }
}

// PAGE SPECIFIC
body[class^="auth-"] {
  .container {
    max-width: 600px;
    padding-top: 20px;
  }
}

body {
  &.customer-add,
  &.user-add {
    .content {
      max-width: 400px;
    }

    .resultHead {
      display: none;
    }

    .resultBody {
      display: list-item !important;

      form[name=form-customer],
      form[name=form-user] {
        width: 100% !important;

        > a {
          display: block;

          &:hover {
            color: white;
          }
        }

        .birth-date {
          padding: 0;
        }

        a.show-privacy-popup,
        a.btn-send-cta {
          width: 100% !important;
        }
      }

      label {
        width: 100% !important;
        padding-right: 0 !important;

        @media #{$md} {
          width: calc(50% - 3px) !important;

          &.padding-right {
            padding-right: 5px !important;
          }

          &.padding-left {
            padding-left: 5px !important;
          }
        }
      }
    }

    .actions {
      width: 100%;

      .button-save-customer,
      .button-save-user {
        width: 100% !important;
      }
    }
  }

  &.customer-edit {
    .resultHead {
      pointer-events: none;

      &::after {
        display: none;
      }
    }

    .resultBody {
      display: list-item !important;

      label.date {
        > div {
          padding-top: 8px;
        }
      }

      a.pdf-link {
        display: block;
        margin: 10px 0;
      }
    }

    .locked {
      canvas {
        pointer-events: none;
      }
    }
  }

  &.auth-login,
  &.auth-register {
    .container {
      max-width: 350px;
    }

    label {
      width: 100%;
      color: white;

      &.form-check-label {
        width: auto;
      }
    }

    input[type=text],
    input[type=email],
    input[type=password] {
      padding: 0;
      color: $cardinale-dark-green;
      font-size: 18px;
      border-width: 0 0 1px;
      border-radius: 2px;

      &:focus {
        border-color: $cardinale-gold;
      }
    }

    button[type=submit] {
      width: 100%;
      color: white;
      background: $cardinale-middle-green;
    }
  }

  &.welcome {
    #app.app {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    nav {
      background: $cardinale-dark-green !important;

      img {
        max-width: 240px !important;
      }

      a.navbar-brand {
        position: relative;
        top: 19vh;
        font-size: 30px;
      }

      .navbar-collapse {
        @media (max-width: 768px) {
          position: absolute;
          top: 10px;
          left: 66px;
        }
      }
    }
  }

  &.customer-search {
    .searchBlock {
      > label {
        font-size: 20px;
      }

      form[name=form-search] {
        max-width: 400px;
        margin: auto;
        margin-bottom: 30px;
      }

      .keyword-wrapper {
        position: relative;

        &::after {
          position: absolute;
          top: 2px;
          right: 14px;
          color: $cardinale-dark-green;
          font-size: 24px;
          font-family: FontAwesome;
          content: "\F002";
        }

        .keyword {
          padding: 12px;
          border-radius: 2px;
        }
      }

      .search-history-track {
        h3 {

        }

        ul.search-history-list {
          width: fit-content;

          > li {
            text-align: left;

            a {
              &:hover {
                color: #dcb274;
              }
            }
          }
        }
      }
    }
  }

  &.customer-index,
  &.customer-search {
    .content {

    }
  }

  &.user-add {
    .resultHead {
      display: none;
    }

    .resultBody {
      display: list-item !important;
    }
  }

  &.user-index {
    .button-delete-user,
    .button-cancel-edit-user,
    .button-update-user {
      display: inline-block !important;
    }

    .button-edit-user {
      display: none;
    }
  }
}

// END - PAGE SPECIFIC

// $('body.customer-add .resultHead').remove();
// $('body.customer-add .resultBody').show();

.ui-datepicker-trigger {
  max-width: 20px;
}

ul.navbar-nav {
  @media #{$tablet} {
    position: absolute;
    top: 30px;
    right: 0;
  }
}

div#ui-datepicker-div {
  border-radius: 6px;

  * {
    border-radius: 360px;
  }

  td {
    span,
    a {
      width: 30px;
      height: 30px;
      padding-top: 4px;
      text-align: center;
    }
  }
}

ul.pagination {
  justify-content: center;

  li.page-item.active {
    span.page-link {
      background-color: $cardinale-gold;
      border-color: $cardinale-gold;
    }
  }

  li.page-item.disabled {
    span.page-link {

    }
  }

  li.page-item {
    //min-width: 90px;

    a.page-link {
      padding: 0.5rem 0.65rem;
      color: $cardinale-middle-green;
      border-color: fade-out($cardinale-gold, 0.7);

      &:hover {
        color: white;
        background-color: fade-out($cardinale-gold, 0.7);
        border-color: fade-out($cardinale-gold, 0.7);
      }
    }
  }
}

.import {
  input {
    display: block !important;
    margin: auto;
    border: 1px solid $cardinale-gold;

    &[type=file] {
      margin-bottom: 17px;
      color: white;
      background: none;
      cursor: pointer;
      transition: 300ms;

      //&:hover {
      //  background: $cardinale-middle-green;
      //  color: white;
      //}
    }
  }
}

footer {
  min-height: 100px;
}

// HELPER

form[name=form-purchase] {
  &:not(.active) {
    label {
      pointer-events: none;
    }
  }

  &.new {
    label {
      pointer-events: initial;
    }
  }
}
// END - HELPER

canvas {
  width: 100%;
  height: 190px;
  background: white;
  border: 1px solid #888;
  border-radius: 2px;
}

.btn.clear {
  margin: 16px 0;
  font-size: 17px;
}

.privacy-policy {
  h1,
  h2,
  h3,
  h4,
  p {
    color: white !important;
  }

  h1 {
    font-weight: bold;
    font-size: 18px;
  }

  h2 {
    font-size: 16px;
  }
}

.privacy-policy-pdf {
  color: black !important;
  font-weight: 400;
  font-size: 8px;

  h1 {
    font-size: 12px;
  }

  h2 {
    font-size: 10px;
  }

  img {
    width: 200px;
  }
}

aside.spinner-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  align-items: center;
  justify-content: center;
  background: rgb(0, 0, 0, 0.5);
  transform: initial;
}
